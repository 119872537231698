body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* fix timepicker behind popup */

/* Solve DatePicker, TimePicker, DropdownList 
pop up goes below when opened in Dialog window */

/* .k-animation-container 
.k-popup .k-child-animation-container */

.timePickerPopUpClass {
  z-index: 999999 !important;
}

/* looks DropDownList not adding timePickerPopUpClass so use this */

.k-animation-container {
  z-index: 999999 !important;
}

.k-grid{
  white-space: nowrap;
}
/* other */

.myTextArea {
  width: 100% !important;
}

.form-row {
  display: flex;
  flex-direction: row;
}
.form-col {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.radioss{
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}
.facetoface{
  width: 50%;
  text-align: right;
}


.message.k-textbox {
  width: 34em;
  outline: none;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}
