.TopBar {
    width: 100%;
    height: 50px;
    background-color: #80bffd29;
    color: rgb(0, 44, 90);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TopBar-left p {
    display: inline-block;
    margin: 0;
    margin-left: 12px;
    font-size: 1.1em;
    font-weight: bold;
}

.TopBar-left {
    flex-shrink: 0;
}

.TopBar-second {
    width: 100%;
    align-self: center;
    margin-left: 12px;
}

.TopBar-right {
    text-align: right;
    align-self: center;
}