.App {
  text-align: left;
  /* box-sizing: border-box; */
}

.App ul {
  padding: 0;
  margin: 0;
}

.App ul li {
  display: inline-block;
  margin: 4px;
  font-size: 0.8em;
}

.projectsLoader {
  display: flex;
  height: 100%;
  /* align-self: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .projectsLoader span{
  font-size: 24px !important;
} */

.hours-alert-messaage {
  /* color: white;
  background-color: #f23c3c; */
  font-size: 20px;
  margin: 0px 10px;
  padding: 0px 20px;
  float: left;
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  height: 32px;
}

.float-left {
  float: left;
}

.text-danger {
  color: red;
}

.k-i-warning {
  background-color: unset !important;
  color: red;
  font-weight: bold;
  font-size: 20px;
}
.text-align-right{
  text-align: right;
}
