.Login{
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    margin-top: 40px;
}

.d-block{
    display: block !important;
  }

  .error{
      color: red;
      text-align: left;
  }