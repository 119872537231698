.Dashboard {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 62px);
    color: #345995;
    background-color: #ebf5ff;
    overflow: hidden;
    padding-bottom: 12px;
}

.Dashboard .leftCol {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    width: 25%;
    height: 100%;
    /* height: calc(100vh - 127px); */
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    padding: 12px;
    margin-left: 12px;
}

.Dashboard .rightCol {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* height: calc(100vh - 127px); */
    background-color: white;
    margin-left: 12px;
    margin-right: 12px;
    overflow-y: auto;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    padding: 12px;
}

.Dashboard .rightUpCol {
    height: 35%;
}

.k-grid-table tr:hover {
    cursor: pointer;
}

.Dashboard .timesheetTable {
    max-height: 35% !important;
}

.bg-green{
    background-color: green !important;
}

.bg-red{
    background-color: red !important;
}

.bg-yellow{
    background-color: yellow !important;
}

.bg-black{
    background-color: black !important;
}
.h-25{
    height: 25px !important;
}
.w-25{
    width: 25px !important;
}
.border-radius{
    border-radius: 50%;
    border: 1px solid #000;
    box-shadow: 2px 2px 10px #999;
}

.k-state-disabled, .k-widget[disabled]{
    pointer-events : unset !important;
}
