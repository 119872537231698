.feed-card {
  width: 128px !important;
  height: 193px !important;
  float: left;
  margin-right: 20px;
  height: 300px !important;
}

.feedtitle {
  text-align: center;
}

.mainfeed {
  float: left;
  width: 100%;
}

.feed-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.float-left{
  float: left;
}
.clear-both{
  clear: both;
}

.news-feeds{
  padding:10px;
  float:left;
  width:90%;
}

.news-title{
  padding-left: 20px;
  color: black;
  text-decoration: none;
}

.news-title:hover{
  text-decoration: underline!important;
}

.image-part
{
  float:left;
}

.title-part
{
   float:left;
  line-height: 85px;
  
}


