.ProjectItemsList {
    height: 100%;
    overflow-y: auto;
    padding-right: 4px;
}

.ProjectItem {
    margin: 2px;
    margin-bottom: 8px;
    border: #92b7dc solid 1px;
    padding: 4px;
    border-radius: 6px;
    opacity: 0.8;
}

.ProjectItem b {
    font-size: 0.9em;
    width: 100%;
}

.ProjectItem:hover {
    cursor: pointer;
    color: black;
    border: #56728d solid 1px;
    opacity: 0.9;
}

.ProjectItem-selected {
    background-color: #f5f5f5;
    color: black;
    border: #ff6358 solid 1px;
    opacity: 1;
}

.ProjectItem-desc {
    margin: 0px;
    font-size: 0.8em;
    font-style: italic;
}

.ProjectItem-desc img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    float: left;
    margin-right: 4px;
}

.ProjectItem-badgeWrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ProjectItem-badgeWrap span {
    flex-shrink: 0;
    background-color: #92b7dc;
}

.float-right{
    float: right !important;
}

.float-left{
    float: left !important;
}

.ProjectItem-Jugendamt {
    margin: 0px;
    font-size: 0.8em;
}

.ProjectItem-Koordinator {
    margin: 0px;
    font-size: 0.8em;
}

.ProjectItem-Wochenstunden {
    margin: 0px;
    font-size: 0.8em;
    color:#ff6358;
}
